import React, { useEffect } from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import {
  FullBackground,
  GetStartedPageContainer,
  Layout,
  SEO,
} from "components";
import { Link as GatsbyLink, navigate } from "gatsby";
import { pricingLookupTableData, routes } from "utils";

export default function GetStartedConfirmationPage({ location }) {
  // Check if confirmation has basic information to display. If not, that means
  // the user navigated to this url directly, so we would like to redirect them
  // back to the Get Started page
  // Note: useEffect throws error in console in local dev, but is fine in compiled preview/prod.

  useEffect(() => {
    try {
      window.gtag("event", "conversion", {
        send_to: "AW-441213032/Bu2SCJmL7_IBEOjAsdIB",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!location.state?.accountType) {
      console.log("getStartedConfirmation redirect to getStartedLanding");
      navigate(routes("getStartedLanding"), { replace: true });
    }
  }, [location.state]);

  const { capacityParams } = pricingLookupTableData;

  const title = "Get Started with ECL | Confirmation";
  // Defaults necessary to pass to GetStartedPageContainer for build time
  let accountType = location.state?.accountType || "Startup";
  let selectedPlan = location.state?.selectedPlan || "A la Carte";
  let discountAmount = location.state?.discountAmount || "0";
  let discountName = location.state?.discountName || "";
  let renewalDiscountAmount = location.state?.renewalDiscountAmount || "0";
  let renewalDiscountName = location.state?.renewalDiscountName || "";
  let startingAt = location.state?.startingAt;
  let privateTutoringCost = location.state?.privateTutoringCost;
  let certificateTrainingCost = location.state?.certificateTrainingCost;
  let pricingTableState = location.state?.data || {
    capacity: capacityParams.Basic.DisplayText,
    renewal: "1 year",
    licenses: "3",
    storage: "1",
    tutoring: "1",
    training: "Level 1 Open",
    level2: false,
    level3: false,
    level4: false,
    level5: false,
  };
  let formData = location.state?.formData || {
    email: "",
    phone: "",
    desiredStartDate: "",
    billingName: "",
    billingCompany: "",
    billingCountry: "United States",
    billingAddress: "",
    billingCity: "",
    billingLocality: "",
    billingState: "",
    billingProvince: "",
    billingCounty: "",
  };

  // Temporarily taking the self-pricing pages offline. No quote route available.
  // const isQuoteRoute = ["Academia", "Startup"].includes(accountType);
  const isQuoteRoute = false;

  return (
    <FullBackground>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `_linkedin_partner_id = "3729265";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            </script>
            <script type="text/javascript">
              (function(l) {
              if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
              window.lintrk.q=[]}
              var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);})(window.lintrk);
            </script>`,
        }}
      />
      <Layout clearNav clearFooter location={location}>
        <SEO image={pageImage} title={title} uri={location.pathname} />
        <GetStartedPageContainer
          step="confirmation"
          state={{
            accountType,
            selectedPlan,
            discountAmount,
            discountName,
            renewalDiscountAmount,
            renewalDiscountName,
            startingAt,
            privateTutoringCost,
            certificateTrainingCost,
            data: pricingTableState,
            formData,
          }}
          showHeader
        >
          <S.Row>
            {isQuoteRoute ? (
              <S.Col>
                A copy of the quote will be sent to your email with a link to
                download your customized quote. A sales representative from
                Emerald Cloud Lab will be in touch as well to answer any
                questions you may have.
              </S.Col>
            ) : (
              <S.Col>
                A sales representative from Emerald Cloud Lab will be in touch
                to answer any questions you may have.
              </S.Col>
            )}
          </S.Row>
          <S.Row className="small">
            <S.Col>
              <GatsbyLink
                to={routes("virtualTour")}
                className="hover-underline"
              >
                <S.RouteLink>
                  <div>Take a Virtual Tour</div>
                  <S.RightArrowIcon />
                </S.RouteLink>
              </GatsbyLink>
            </S.Col>
          </S.Row>
          <S.Row className="large">
            <S.Col>
              <GatsbyLink
                to={routes("instrumentation")}
                className="hover-underline"
              >
                <S.RouteLink>
                  <div>View Scientific Instrumentation</div>
                  <S.RightArrowIcon />
                </S.RouteLink>
              </GatsbyLink>
            </S.Col>
          </S.Row>
        </GetStartedPageContainer>
      </Layout>
    </FullBackground>
  );
}

const S = {};

S.Row = styled(Row)`
  margin-bottom: 2rem;
  &.small {
    margin-bottom: 0.5rem;
  }
  &.large {
    margin-bottom: 10rem;
  }
`;

S.Col = styled(Col)`
  color: #b1bac2;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2rem;
`;

S.RouteLink = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
`;

S.RightArrowIcon = styled(FaChevronRight)`
  background-color: transparent;
  color: ${({ theme }) => theme.greenLink};
  padding: 0.2rem;
  height: 16px;
`;
